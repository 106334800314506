import { IUserRelationship } from '@/store/models/user-relationship';
import Link from 'next/link';

export const UserRelationshipLink: React.FC<{ user: IUserRelationship; className?: string }> = ({
  user,
  children,
  className,
}) => {
  if (!user.clientLink) {
    return <>{children}</>;
  }

  return (
    <Link href={user.clientLink}>
      <a className={className}>{children}</a>
    </Link>
  );
};

export default UserRelationshipLink;
