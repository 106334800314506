import { observer } from 'mobx-react';
import Link from 'next/link';

import { useStore } from '@/store';
import { UserAvatar } from '@/components/user/avatar';

import BrushIcon from '@/assets/icons/brush.svg';
import LightbulbIcon from '@/assets/icons/lightbulb.svg';
import GloveIcon from '@/assets/icons/glove.svg';

export const MyTrueNorth = observer(() => {
  const store = useStore();

  if (!store.quiz.latest?.complete) {
    return null;
  }

  const hasTrueNorthStatement =
    store.truenorth.latest && typeof store.truenorth.latest.truenorth_statement !== 'undefined';

  return (
    <div className="c-card">
      <h6 className="u-mb-4 u-text-xs u-text-medium">
        My True North <span className="c-divider"></span>
      </h6>
      <div className="o-row">
        <div className="o-col-6@lg u-pr-5@lg">
          <div className="u-flex u-mb-2 u-mb-0@lg">
            <div className="u-flex-shrink-0 u-mr-2">
              <UserAvatar />
            </div>
            <div>
              <blockquote>
                {hasTrueNorthStatement
                  ? store.truenorth.latest!.truenorth_statement
                  : 'You do not have a True North statement yet, please add one.'}
              </blockquote>
              <p className="u-text-xs">
                <Link href="/guided-journal">
                  <a>{hasTrueNorthStatement ? 'Change Statement' : 'Add Statement'}</a>
                </Link>
                {' / '}
                <Link href="/quiz">
                  <a>Change Attributes</a>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="o-col-6@lg">
          <div className="o-row">
            <div className="o-col-4@md">
              <div className="c-results-summary u-pl-0 u-mb-2 u-mb-0@md">
                <h5 className="c-results-summary__heading u-text-xs u-text-primary-grey-150">
                  <span>
                    <LightbulbIcon />{' '}
                  </span>
                  Values
                </h5>
                <ul className="c-results-summary__list u-text-xs">
                  {store.quiz.latest.attributes.values.map((item) => (
                    <li key={item.key}>{item.label}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="o-col-4@md">
              <div className="c-results-summary u-pl-0 u-mb-2 u-mb-0@md">
                <h5 className="c-results-summary__heading u-text-xs u-text-primary-grey-150">
                  <span>
                    <GloveIcon />{' '}
                  </span>
                  Strengths
                </h5>
                <ul className="c-results-summary__list u-text-xs">
                  {store.quiz.latest.attributes.strengths.map((item) => (
                    <li key={item.key}>{item.label}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="o-col-4@md">
              <div className="c-results-summary u-pl-0 u-mb-2 u-mb-0@md">
                <h5 className="c-results-summary__heading u-text-xs u-text-primary-grey-150">
                  <span>
                    <BrushIcon />{' '}
                  </span>
                  Skills
                </h5>
                <ul className="c-results-summary__list u-text-xs">
                  {store.quiz.latest.attributes.skills.map((item) => (
                    <li key={item.key}>{item.label}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MyTrueNorth;
