import Link from 'next/link';

import { ButtonLink } from '@/components/button';
import DancingIllustration from '@/assets/illustrations/dancing.svg';
import PlusIcon from '@/assets/icons/plus.svg';

const messages = [
  'What has recently contributed to your purpose journey?',
  'What was been a highlight of your semester?',
  'What has affected your thoughts and ambitions?',
  "What's a memorable experience you've had lately?",
  'What recent event, insight, or relationship helped you gain more perspective?',
];

export const NewExperience = () => {
  const dayIndex = Math.ceil(new Date().getTime() / 60 / 60 / 24);
  const messageIndex = dayIndex % messages.length;

  return (
    <div className="c-card c-card--blue-gradient u-text-white">
      <h5 className="u-text-medium">
        New Experience <span className="c-divider u-bg-white"></span>
      </h5>
      <p className="u-text-white">{messages[messageIndex]}</p>
      <DancingIllustration className="u-mb-2" />
      <Link href="/experiences/new">
        <ButtonLink buttonType="white" full>
          <PlusIcon className="c-button__icon o-svg-icon o-svg-fill" /> New Experience
        </ButtonLink>
      </Link>
    </div>
  );
};

export default NewExperience;
