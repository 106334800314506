import { observer } from 'mobx-react';
import { useState } from 'react';
import { useMount } from 'react-use';

import { useStore } from '@/store';
import { formatDate } from '@/utils/date';

const getCurrentFormattedDate = () => {
  const d = new Date();

  return formatDate(d, { dayOfWeek: true, year: false });
};

export const WelcomeWidget = observer(() => {
  const store = useStore();
  const [date, setDate] = useState(getCurrentFormattedDate());

  useMount(() => {
    const intervalId = setInterval(() => {
      setDate(() => getCurrentFormattedDate());
    }, 1000 * 60);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className="o-row u-items-center u-mb-5">
      <div className="o-col-9@md">
        {store.user && <h1 className="u-mb-2 u-mb-0@md">Welcome back {store.user.first_name}!</h1>}
      </div>
      <div className="o-col-3@md">
        <p className="u-mb-0 u-text-xs u-text-primary-grey-100 u-text-right@md">{date}</p>
      </div>
    </div>
  );
});

export default WelcomeWidget;
