import { observer } from 'mobx-react';
import { useMount } from 'react-use';

import { useStore } from '@/store';
import GuidedJournalCurrentQuestion from '@/components/guided-journal/current-question';

export const GuidedJournalWidget = observer(() => {
  const store = useStore();

  const isTrueNorthCompleted = store.truenorth.latest && store.truenorth.latest.complete;

  useMount(() => {
    if (!isTrueNorthCompleted && !store.truenorth.editable) {
      store.truenorth.loadEditable();
    }
  });

  if (isTrueNorthCompleted) {
    return null;
  }

  return (
    <div className="u-my-10">
      <GuidedJournalCurrentQuestion />
    </div>
  );
});

export default GuidedJournalWidget;
