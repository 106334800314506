import Head from 'next/head';

import Header from '@/components/header';
import MyTrueNorth from '@/components/dashboard/my-true-north';
import RecentActivity from '@/components/dashboard/recent-activity';
import MyGuides from '@/components/dashboard/my-guides';
import NewExperience from '@/components/dashboard/new-experience';
import GuidedJournalWidget from '@/components/dashboard/guided-journal';
import WelcomeWidget from '@/components/dashboard/welcome';

import CampingIllustration from '@/assets/illustrations/camping.svg';
import MountainBgIllustration from '@/assets/illustrations/mountain-bg-v2.svg';

export default function DashboardPage() {
  return (
    <div>
      <Head>
        <title>Dashboard | True North</title>
      </Head>

      <Header />
      <div className="o-container u-mt-6">
        <WelcomeWidget />
        <div className="o-row">
          <div className="o-col-9@lg u-pr-5@lg u-mb-4 u-mb-0@lg">
            <GuidedJournalWidget />
            <RecentActivity />
            <MyTrueNorth />
          </div>
          <div className="o-col-3@lg">
            <NewExperience />
            <MyGuides />
          </div>
        </div>
      </div>

      <div className="c-illustration c-illustration--campsite">
        <MountainBgIllustration className="c-illustration__asset" />
        <CampingIllustration className="c-illustration__asset" />
      </div>
    </div>
  );
}
