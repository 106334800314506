import Link from 'next/link';
import { observer } from 'mobx-react';
import { useMount } from 'react-use';

import { formatDate } from '@/utils/date';

import { useStore } from '@/store';
import { IActivity } from '@/store/stores/activities';

import { UserAvatar } from '@/components/user/avatar';
import FetchSuspense from '@/components/fetch-suspense';
import UserRelationshipLink from '@/components/user/link';

import GloveIcon from '@/assets/icons/glove.svg';
import CommentIcon from '@/assets/icons/comment.svg';
import ActivitiesEmptyIllustration from '@/assets/illustrations/activities-empty.svg';

const Activity = observer(({ activity }: { activity: IActivity }) => {
  let icon = <CommentIcon className="o-svg-icon o-svg-fill u-mr-1" />;

  if (activity.type === 'reaction') {
    icon = <GloveIcon className="o-svg-icon o-svg-fill u-mr-1" />;
  }

  return (
    <li className="u-py-2 u-border-b-1 u-border-primary-grey-50">
      <div className="o-row">
        <div className="o-col-2@md u-mb-1">
          {activity.type === 'invitation' ? (
            <Link
              href={`/${activity.asset.type === 'guide' ? 'students' : 'guides'}/${
                activity.asset.id
              }`}
            >
              <a>
                <small className="u-mb-2 u-text-primary-grey-100">
                  {icon} {formatDate(activity.created_at, { year: false })}
                </small>
              </a>
            </Link>
          ) : (
            <Link href={`/experiences/${activity.asset.id}`}>
              <a>
                <small className="u-mb-2 u-text-primary-grey-100">
                  {icon} {formatDate(activity.created_at, { year: false })}
                </small>
              </a>
            </Link>
          )}
        </div>
        <div className="o-col-10@md">
          <div className="u-flex">
            <div className="u-flex-shrink-0 u-mr-1">
              <UserRelationshipLink user={activity.author} className="u-inline-block">
                <UserAvatar small user={activity.author} />
              </UserRelationshipLink>
            </div>
            <div>
              <p className="u-mb-0 u-text-xs">
                <UserRelationshipLink user={activity.author} className="c-link c-link--black">
                  {activity.author.displayName}
                </UserRelationshipLink>
                {activity.type === 'invitation' ? (
                  <>
                    {activity.type === 'invitation' ? ' invited you to be a ' : ''}
                    <Link
                      href={`/${activity.asset.type === 'guide' ? 'students' : 'guides'}/${
                        activity.asset.id
                      }`}
                    >
                      <a className="c-link c-link--black">{activity.title}</a>
                    </Link>
                  </>
                ) : (
                  <>
                    {activity.type === 'experience' ? ' shared an experience ' : ''}
                    {activity.type === 'comment' ? ' commented on ' : ''}
                    {activity.type === 'reaction' ? ' liked ' : ''}
                    <Link
                      href={
                        activity.type === 'comment'
                          ? `/experiences/${activity.asset.experience_id}`
                          : `/experiences/${activity.asset.id}`
                      }
                    >
                      <a className="c-link c-link--black">{activity.title}</a>
                    </Link>
                  </>
                )}
              </p>
              <small>{activity.snippet}</small>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
});

export const RecentActivity = observer(() => {
  const store = useStore();
  const activites = store.activites.activities;

  useMount(() => {
    store.activites.loadActivities();
  });

  return (
    <div className="c-card">
      <h6 className="u-text-xs u-text-medium">
        Recent Activity <span className="c-divider"></span>
      </h6>
      <FetchSuspense state={store.activites.activitiesFetchState}>
        {store.activites.isEmpty ? (
          <div className="u-text-center u-pb-1">
            <ActivitiesEmptyIllustration className="u-mr-10@md u-ml-10@md u-mr-5@sm u-ml-5@sm u-mb-3" />
            <h4 className="u-mb-1">No activities yet!</h4>
            <p className="u-mb-2 u-text-sm">
              As guides start leaving comments and liking your experiences you will be notified
              here.
            </p>
          </div>
        ) : (
          <ul className="u-mb-0">
            {activites.map((activity) => (
              <Activity activity={activity} key={activity.id} />
            ))}
          </ul>
        )}
      </FetchSuspense>
    </div>
  );
});

export default RecentActivity;
