import { observer } from 'mobx-react';
import Link from 'next/link';
import { useMount } from 'react-use';

import { useStore } from '@/store';

import { UserAvatar } from '@/components/user/avatar';
import FetchSuspense from '@/components/fetch-suspense';
import UserRelationshipLink from '@/components/user/link';

import SunIllustration from '@/assets/illustrations/sun.svg';
import GuidesIllustration from '@/assets/illustrations/guides.svg';

export const MyGuides = observer(() => {
  const store = useStore();

  useMount(() => {
    store.guides.loadList();
  });

  return (
    <div className="c-card">
      <h6 className="u-text-xs u-text-medium">
        My Guides <span className="c-divider"></span>
      </h6>
      <FetchSuspense state={store.guides.listFetchState}>
        <ul className="u-mb-0">
          {store.guides.list?.data.length === 0 && 
            <div className="u-text-center">
              <div className="c-illustration c-illustration--guides">
                <SunIllustration className="c-illustration__asset" />
                <GuidesIllustration className="c-illustration__asset" />
              </div>
              <hr />
              <h6 className="u-mb-1">No guides invited.</h6>
              <p className="u-mb-0 u-text-sm">
                To share your experiences start by inviting a guide!
              </p>
            </div>
          }
          {store.guides.list?.data.map((guide) => (
            <li className="u-py-1 u-border-b-1 u-border-primary-grey-50" key={guide.id}>
              <div className="u-flex u-items-center">
                <div className="u-flex-shrink-0 u-mr-1">
                  <UserRelationshipLink user={guide.guide}>
                    <UserAvatar small user={guide.guide} />
                  </UserRelationshipLink>
                </div>
                <div>
                  <p className="u-mb-0 u-text-xs">
                    <UserRelationshipLink user={guide.guide} className="c-link c-link--black">
                      {guide.guide.displayName}
                    </UserRelationshipLink>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </FetchSuspense>
      {Boolean(store.guides.list?.data.length) && (
        <Link href="/guides">
          <a className="u-text-xs">View All Guides</a>
        </Link>
      )}
    </div>
  );
});

export default MyGuides;
